.sidebar {
    width: 20vw;
    height: 100vh;
    padding-left: 10px;
    position: fixed;
    left: 0;
    top: 0;
    transition: left 0.5s;
    background-color: white;
    border-right: 1px var(--dark-color) solid;
    z-index: 100;
}

.contents-heading {
    font-family: var(--font-family-text);
    font-size: 30px;
}

.sidebar-treeview-container {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {

    .sidebar {
        width: 80vw;
    }

    .sidebar-hidden {
        left: -110vw;
    }    

    .sidebar-visible {
        left: 0px;
    }

    .sidebar-treeview-container {
        margin-top: 50px;
        height: 60vh;
        border-top: 1px var(--dark-color) solid;
        border-bottom: 1px var(--dark-color) solid;
    }

    .contents-heading {
        margin-top: 40px;
    }
}



.tree-item{
    margin-top: 10px !important;
}

.MuiTreeItem-content.Mui-selected {
    background-color: var(--blue-color) !important;
    border: 2px var(--dark-color) dotted;
}

.MuiTreeItem-label {
    font-family: var(--font-family-text) !important;
    font-size: 20px !important;
    user-select: none; 
}

.mobile-nav-sidebar {
    margin-top: 100px;
}

.mobile-nav-sidebar-navitem {
    margin-top: 20px;
    margin-left: 20px;
}

@media only screen and (min-width: 600px) {
    .mobile-nav-sidebar {
        display: none;
    }
    
    .sidebar-treeview-container {
        margin-top: 190px;
    }
    
    .sidebar-hidden-large-screen {
        display: none;
    }
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}
  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
}

.App {
  background-color: var(--white-color);
  top: 0px;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}

.content-container {
  margin-left: 25vw;
  margin-top: 150px;
  padding-bottom: 100px;
}

.content {
    min-height: 100vh;
    width: 48vw;
    justify-content: center;
    font-size: 10px;
    color: black;
}

.content h1 {
  font-size: 48px;
  font-family: var(--font-family-content-text);
}

.content h2 {
  font-size: 42px;
  font-family: var(--font-family-content-text);
  margin-top: 50px;
  margin-bottom: 0px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 15px;
  text-decoration-color: var(--blue-color);
}

.content h3 {
  font-size: 30px;
  font-family: var(--font-family-content-text);
  margin-top: 50px;
  margin-bottom: 0px;
}

.content .h2-anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}

.content p {
  font-size: 24px;
  font-family: var(--font-family-content-text);
  margin-top: 25px;
  margin-bottom: 0px;
}

.content ul li::marker {
  color: var(--blue-color); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
}

.content ul li {
  font-size: 24px;
  font-family: var(--font-family-content-text);
}

.content ol li::marker {
  color: var(--blue-color); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
}

.content ol li {
  font-size: 24px;
  font-family: var(--font-family-content-text);
}

code {
  color: var(--white-color);
  background-color: var(--dark-color);
  font-size: 18px;
  padding: 20px;
  width: 600px;
  display: block;
  border-radius: 15px;
  margin-top: 36px;
}

img + em { 
  text-align: center;
  display: block;
  font-weight: bold;
  font-style: normal;
}
  
.App-link {
    color: #61dafb;
}
  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}


@media only screen and (max-width: 600px) {
  .content-container {
    margin-left: 5vw;
    margin-top: 100px;
  }

  .content {
    width: 90vw;
  }

  .content h1 {
    font-size: 32px;
  }

  .content h2 {
    font-size: 28px;
    text-decoration-thickness: 10px;
  }

  .content p {
    font-size: 20px;
  }

  .content ul li {
    font-size: 20px;
  }

  .content p img {
    width: 90vw;;
  }
}
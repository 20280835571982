.landing-page-container {
    width: 100vw;
    background-color: #EDEDD5;
    height: 100vh;
}

.landing-page-head-turner-mobile-image-container {
    display: none;
}

.landing-page-info-container{
    display: inline-block;
    position: relative;
    width: 40vw;
    padding-left: 180px;
    top: -30vh;
}

.landing-page-heading {
    font-family: var(--font-family-text);
    font-size: 40px;
    margin-top: 60px;
}

.nav-items-container {
    left: -40px;
    margin-top: 60px;
    position: relative;
}

.landing-page-nav-item {
    display: inline-block;
    margin-left: 30px;
}

.landing-page-head-turner-image-container {
    display: inline-block;
    margin-top: 100px;
}

.landing-page-head-turner-image {
    width: 45vw;
}

@media only screen and (max-width: 600px) {
    .landing-page-container {
        height: 120vh;
    }

    .landing-page-head-turner-mobile-image-container {
        display: block;
        margin-top: 20px;
    }

    .landing-page-info-container {
        position: relative;
        width: 90vw;
        padding-left: 20px;
        top: 6vh;
    }

    .landing-page-heading {
        margin-top: 30px;
        font-size: 30px;
    }

    .nav-items-container {
        left: 40px;
    }

    .landing-page-nav-item {
        display: block;
        margin-top: 50px;
    }

    .landing-page-head-turner-image {
        width: 80vw;
    }

    .landing-page-head-turner-image-container {
        display: none;
    }
}
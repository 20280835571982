.mobile-navbar {
    display: none;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: white;
    border-bottom: 1px var(--dark-color) solid;
    z-index: 200;
}

.mobile-navbar-menu-button {
    display: inline-block;
    position: relative;
    margin-left: 20px;
    top: -9px;
    font-size: 30px;
}

.mobile-navbar-logo-container {
    display: inline-block;
    fill: var(--dark-color);
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 6px;
}

@media only screen and (max-width: 600px) {
    .mobile-navbar {
      display: block;
    }
}
  
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

:root {
  --font-family-logo: 'Quicksand', sans-serif;
  --font-family-text: 'Quicksand', sans-serif;
  --font-family-content-text: 'Quicksand', sans-serif;
  --dark-color: #1D1C1A;
  --light-gray: #909090;
  --white-color: #F6F8FA;
  --blue-color: #A4ECD7;
  --darker-blue-color: #13B3AC;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
    cursor: pointer;  
}

.stackingup-logo {
    display: inline-block;
    width: 80px;
    fill: #1D1C1A;
}

.logo-text {
    margin-left: 20px;
    display: inline-block;
    font-family: var(--font-family-logo);
    font-size: 35px;
    top: -20px;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .stackingup-logo {
        width: 55px;
    }
  
    .logo-text {
        font-size: 24px;
        top: -14px;
        margin-left: 10px;
    }
  }
  
.navbar {
    width: 100%;
    height: 100px;
    padding: 10px;
    position: fixed;
    top: 0;
    transition: top 0.5s;
    background-color: white;
    border-bottom: 1px var(--dark-color) solid;
    z-index: 200;
}


@media only screen and (max-width: 600px) {
    .navbar {
        display: none;
    }
}

.navbar--hidden {
    top: -120px;
}

.logo-container {
    display: inline-block;
    fill: var(--dark-color);
    margin-left: 220px;
    margin-top: 4px;
}

.navitems-container {
    display: inline-block;
    margin-left: 30vw;
    top: -25px;
    position: relative;
}

.navbar-navitem {
    display: inline-block;
    margin-left: 40px;
}
.donate-page {
    background-color: #F7F4C9;
    top: 0px;
    position: absolute;
    width: 100vw;
    overflow-x: hidden;
}

.tip-jar-image-container {
    margin-top: 10vh;
    width: 40vw;
    text-align: center;
    display: inline-block;
    position: relative;
}

.tip-jar-image {
    width: 60vw;
    position: relative;
    display: inline-block;
}

.donate-page-headings {
    width: 40vw;
    position: relative;
    display: inline-block;
    top: -100px;
    margin-left: 120px;
}

.donate-page-heading {
    font-family: var(--font-family-logo);
    font-size: 60px;
    color: #850A09;
}

.donate-page-explanation {
    width: 600px;
    font-family: var(--font-family-logo);
    margin-top: 100px;
    font-size: 30px;
}

.hand-waving {
    margin-top: 100px;
}

@media only screen and (max-width: 600px) {
    .tip-jar-image-container{
        display: block;
    }

    .tip-jar-image {
        width: 90vw;
    }

    .donate-page-headings {
        display: block;
        width: 90vw;
        margin-left: 26px;
        top: 0px;
    }

    .donate-page-heading {
        font-size: 45px;
    }

    .donate-page-explanation {
        width: 90vw;
        font-size: 24px;
        margin-top: 40px;
    }

    .hand-waving {
        margin-top: 60px;
    }

}

.donate-button-outlink-container {
    margin-top: 100px;
    margin-left: 37vw;
    width: 100vw;
    margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
    .donate-button-outlink-container {
        margin-left: 7vw;
    }
}
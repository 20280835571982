.about-page {
    background-color: #ECBBBB;
    top: 0px;
    position: absolute;
    width: 100vw;
    overflow-x: hidden;
}

.about-head-turner-first-dialogue {
    color: var(--dark-color);
    margin-top: 300px;
    font-size: 60px;
    font-family: var(--font-family-text);
    text-align: center;
}

.card-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 120px;
  }

.about-head-turner-second-dialogue {
    color: var(--dark-color);
    margin-top: 60px;
    font-size: 60px;
    font-family: var(--font-family-text);
    text-align: center;
    margin-bottom: 60px;
}

.consultation-call-container {
    width: 100vw;
    background-color: var(--white-color);
    padding-top: 120px;
    padding-bottom: 120px;
}

.consultation-call-heading {
    color: var(--dark-color);
    font-size: 60px;
    display: block;
    font-family: var(--font-family-text);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 15px;
    text-decoration-color: var(--blue-color);
}

@media only screen and (max-width: 600px) {
    .about-head-turner-first-dialogue {
        font-size: 40px;
        margin-top: 150px;
    }
    .card-image {
        width: 100%;
        margin-top: 30px;
    }
    .about-head-turner-second-dialogue {
        font-size: 40px;
        margin-top: 20px;
    }
    .consultation-call-heading {
        font-size: 40px;
    }
}



.consultation-call-text-container {
    color: var(--dark-color);
    font-size: 24px;
    font-family: var(--font-family-text);
    text-align: center;
    width: 75vw;
    margin: auto;
}

.consultation-call-text {
    color: var(--dark-color);
    font-size: 24px;
    font-family: var(--font-family-text);
    margin-top: 100px;
}

.workshop-call-container {
    width: 100vw;
    padding-top: 120px;
    padding-bottom: 120px;
}

.workshop-call-heading {
    color: var(--dark-color);
    font-size: 60px;
    display: block;
    font-family: var(--font-family-text);
}

.workshop-call-text-container {
    color: var(--dark-color);
    font-size: 24px;
    font-family: var(--font-family-text);
    text-align: center;
    width: 75vw;
    margin: auto;
}

.workshop-call-text {
    color: var(--dark-color);
    font-size: 24px;
    font-family: var(--font-family-text);
    margin-top: 100px;
}

.hand-waving {
    margin-top: 40px;
    font-size: 60px;
    text-align: center;
    margin-bottom: 120px;
}